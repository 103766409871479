<template>
  <div class="flex justify-center">
    <div>
      <a href=".">
        <h1><img width="600" height="315" rel="preload" src="../assets/Shiina_Murakami_Vuejs_Up_And_Running.webp" alt="Clara Moon title" /></h1>
        </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "titleCard",
  components: {},
};
</script>
